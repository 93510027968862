@import 'src/assets/scss/partials/variables';
@import "src/styles/_mixins";

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  //background-image: url(../assets/images/Gradient_background.svg);
  font-family: $font-family-myriad-pro !important;;
  padding-top: 4rem;
}

.mb-notification-banner {
  margin-top: 7.4rem;
  width: 100%;
  height: 3rem;
  background-color: #51A2DE;
  margin-bottom: 2rem;
  text-align: center;
  padding-top: 0.5rem;

  span {
    color: white;
    vertical-align: center;
    line-height: 1.2;
  }

  @include md {
    margin-top: 4rem;
    font-size: 0.8rem;
  }
}

.mb-disabled-field {
  background-color: #f2f2f2 !important;
  color: #6a6a6a !important;
}

.mb-background-layer {
  background: radial-gradient(circle at 10% 10%, #FF7D00 10%, #C82B36 50%, #3C1192 90%);
  z-index: -1;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;

  &::before {
    z-index: -1 !important;
    background-color: inherit !important;
    content: "";
    background-image: url("../assets/images/left.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    max-width: 220px;
    max-height: 545px;
    width: 30vw;
    height: 100%;
    min-width: 90px;
    left: -5px !important;
    top: 35vh !important;
    position: fixed;
  }

  &::after {
    z-index: -1 !important;
    background-color: inherit;
    content: "";
    background-image: url("../assets/images/right.svg");
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 338px;
    max-height: 416px;
    width: 50vw;
    height: 100%;
    left: auto;
    animation: none;
    right: -5px;
    top: 15vh;
    position: fixed;
  }
}

h1, .h1, h2, .h2, h3, .h3 {
  font-family: $font-family-myriad-light !important;
}

.mb-section-title {
  text-align: left !important;
  font-size: 20px !important;
  font-weight: normal !important;
  height: 24px;
  width: 410px;
  letter-spacing: -0.03px;
  line-height: 29px;
}

// GIGYA CLASSES

.gigya-screen.portrait {
  width: 50% !important;
  font-size: 15px;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include md {
    width: 80% !important;
  }

  @include lg {
    width: 80% !important;
  }
}

.gigya-screen-content {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: white;
  border-radius: 0.6rem 0.6rem 0.6rem 0.6rem;
  font-family: $font-family-myriad-pro !important;

  @include md {
    width: 80% !important;
  }
}

.gigya-register-form, .gigya-profile-form {
  width: 100% !important;
}

h1.gigya-screen-caption {
  width: 50% !important;
  height: 4rem !important;
  background-color: white;
  border-radius: 0.6rem 0.6rem 0.6rem 0.6rem;
  margin: 1.875rem auto 3.125rem !important;
  text-align: center !important;
  transform: none !important;
  font-family: $font-family-myriad-light !important;
  font-size: 2.25rem !important;
  font-weight: 300 !important;
  letter-spacing: -0.003rem !important;
  line-height: 3.625rem !important;
  padding-left: 0 !important;

  @include md {
    width: 77% !important;
  }
}

.gigya-layout-cell {
  display: none;
}

.gigya-composite-control {
  width: 100% !important;
  font-family: $font-family-myriad-pro !important;
  color: black !important;
}


#gigya-complete-registration-screen .gigya-layout-row {
  display: unset !important;
}

.gigya-layout-row, .gigya-layout-ro {
  display: grid !important;
  grid-column-gap: 1rem !important;
  padding: 0.5rem !important;

  @include md {
    display: inline !important;
  }
}

.gigya-legend {
  display: block !important;
}

.gigya-screen {
  width: 75% !important;
  font-family: $font-family-myriad-pro !important;

  .gigya-label-text {
    font-family: $font-family-myriad-pro !important;
    color: $black !important;
  }

  .gigya-legend-text {
    font-family: $font-family-myriad-pro !important;
    color: $black !important;
  }

  .gigya-composite-control.gigya-composite-control-textbox input:focus,
  .gigya-composite-control.gigya-composite-control-dropdown select:focus,
  .gigya-composite-control.gigya-composite-control-password input:focus,
  .gigya-composite-control.gigya-composite-control-fieldset input:focus,
  .gigya-composite-control.gigya-composite-control-fieldset select:focus {
    border: 1px solid $brand-primary !important;
    box-shadow: none;
  }

  .gigya-composite-control.gigya-composite-control-textbox input,
  .gigya-composite-control.gigya-composite-control-dropdown select,
  .gigya-composite-control.gigya-composite-control-password input,
  .gigya-composite-control.gigya-composite-control-fieldset input,
  .gigya-composite-control.gigya-composite-control-fieldset select {
    border: 1px solid $black;
    padding-left: 0.44rem !important;
    color: $black !important;
  }
}

select {
  appearance: none !important;
  background-image: url(../assets/images/chevron.svg) !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 7px) center;
}

.gigya-screen .gigya-error-msg.gigya-error-msg-active {
  text-align: left !important;
  border: solid 1px #FF7079 !important;
  color: black !important;
  font-size: 1rem !important;
  border-radius: 0.28rem;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  padding-left: 0.2rem !important;
  text-indent: 0.2rem !important;
  font-family: $font-family-myriad-pro !important;
  font-weight: normal !important;
  overflow: hidden;
  display: flex !important;
  align-items: center !important;
}

.gigya-screen .gigya-error-msg.gigya-error-msg-active:before {
  content: url(../assets/images/circle-close.svg);
  background-color: #FF7079;
  margin: -4px;
  margin-right: 5px;
  text-indent: 0;
  padding: 3px;
  display: flex !important;
  justify-content: center !important;
}

.gigya-valid {
  border: 1px solid $corporate-violet !important;
}

input.gigya-input-text.gigya-error {
  border: 1px solid #FF7079 !important;
}

.gigya-screen .separator::before, .gigya-screen .separator::after {
  border-bottom: 1px solid $corporate-orange !important;
}

.gigya-screen .separator:not(:empty)::after {
  margin-left: 20px !important
}

.gigya-screen .separator:not(:empty)::before {
  margin-right: 20px !important
}

.gigya-show-checkmark.gigya-valid {
  background-image: none !important;
}

.gigya-screen input[type=submit] {
  font-size: 16px !important;
  font-family: myriad-pro !important;
}

.gigya-screen input[type=button] {
  font-size: 16px !important;
  font-family: myriad-pro !important;
}

.gigya-screen a {
  font-family: myriad-pro !important;
}

.gigya-screen-dimmer {
  display: none !important;
}

.gigya-screen .separator {
  display: none !important;
}

.content:after {
  content: '';
  display: block;
}
.mb-footer-container,
.content:after {
  height: 5rem;
}
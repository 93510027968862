* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  padding-top: 4rem;
  font-family: myriad-pro, sans-serif !important;
}

.mb-notification-banner {
  width: 100%;
  height: 3rem;
  text-align: center;
  background-color: #51a2de;
  margin-top: 7.4rem;
  margin-bottom: 2rem;
  padding-top: .5rem;
}

.mb-notification-banner span {
  color: #fff;
  vertical-align: center;
  line-height: 1.2;
}

@media (width <= 991px) {
  .mb-notification-banner {
    margin-top: 4rem;
    font-size: .8rem;
  }
}

.mb-disabled-field {
  color: #6a6a6a !important;
  background-color: #f2f2f2 !important;
}

.mb-background-layer {
  z-index: -1;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 10% 10%, #ff7d00 10%, #c82b36 50%, #3c1192 90%) 0 0 / cover;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.mb-background-layer:before {
  content: "";
  max-width: 220px;
  max-height: 545px;
  width: 30vw;
  height: 100%;
  min-width: 90px;
  position: fixed;
  z-index: -1 !important;
  background-color: inherit !important;
  background-image: url("left.dadab8dd.svg") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  top: 35vh !important;
  left: -5px !important;
}

.mb-background-layer:after {
  background-color: inherit;
  content: "";
  max-width: 338px;
  max-height: 416px;
  width: 50vw;
  height: 100%;
  background-image: url("right.54d8d7cf.svg");
  background-repeat: no-repeat;
  background-size: contain;
  animation: none;
  position: fixed;
  top: 15vh;
  left: auto;
  right: -5px;
  z-index: -1 !important;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-family: myriad-pro-light, sans-serif !important;
}

.mb-section-title {
  height: 24px;
  width: 410px;
  letter-spacing: -.03px;
  line-height: 29px;
  text-align: left !important;
  font-size: 20px !important;
  font-weight: normal !important;
}

.gigya-screen.portrait {
  font-size: 15px;
  width: 50% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (width <= 991px) {
  .gigya-screen.portrait {
    width: 80% !important;
  }
}

@media (width <= 1279px) {
  .gigya-screen.portrait {
    width: 80% !important;
  }
}

.gigya-screen-content {
  width: 50%;
  background-color: #fff;
  border-radius: .6rem;
  margin-left: auto;
  margin-right: auto;
  font-family: myriad-pro, sans-serif !important;
}

@media (width <= 991px) {
  .gigya-screen-content {
    width: 80% !important;
  }
}

.gigya-register-form, .gigya-profile-form {
  width: 100% !important;
}

h1.gigya-screen-caption {
  background-color: #fff;
  border-radius: .6rem;
  width: 50% !important;
  height: 4rem !important;
  text-align: center !important;
  letter-spacing: -.003rem !important;
  margin: 1.875rem auto 3.125rem !important;
  padding-left: 0 !important;
  font-family: myriad-pro-light, sans-serif !important;
  font-size: 2.25rem !important;
  font-weight: 300 !important;
  line-height: 3.625rem !important;
  transform: none !important;
}

@media (width <= 991px) {
  h1.gigya-screen-caption {
    width: 77% !important;
  }
}

.gigya-layout-cell {
  display: none;
}

.gigya-composite-control {
  width: 100% !important;
  color: #000 !important;
  font-family: myriad-pro, sans-serif !important;
}

#gigya-complete-registration-screen .gigya-layout-row {
  display: unset !important;
}

.gigya-layout-row, .gigya-layout-ro {
  grid-column-gap: 1rem !important;
  padding: .5rem !important;
  display: grid !important;
}

@media (width <= 991px) {
  .gigya-layout-row, .gigya-layout-ro {
    display: inline !important;
  }
}

.gigya-legend {
  display: block !important;
}

.gigya-screen {
  width: 75% !important;
  font-family: myriad-pro, sans-serif !important;
}

.gigya-screen .gigya-label-text, .gigya-screen .gigya-legend-text {
  color: #000 !important;
  font-family: myriad-pro, sans-serif !important;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-textbox input:focus, .gigya-screen .gigya-composite-control.gigya-composite-control-dropdown select:focus, .gigya-screen .gigya-composite-control.gigya-composite-control-password input:focus, .gigya-screen .gigya-composite-control.gigya-composite-control-fieldset input:focus, .gigya-screen .gigya-composite-control.gigya-composite-control-fieldset select:focus {
  box-shadow: none;
  border: 1px solid #3c1192 !important;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-textbox input, .gigya-screen .gigya-composite-control.gigya-composite-control-dropdown select, .gigya-screen .gigya-composite-control.gigya-composite-control-password input, .gigya-screen .gigya-composite-control.gigya-composite-control-fieldset input, .gigya-screen .gigya-composite-control.gigya-composite-control-fieldset select {
  border: 1px solid #000;
  color: #000 !important;
  padding-left: .44rem !important;
}

select {
  background-position: calc(100% - 7px);
  appearance: none !important;
  background-image: url("chevron.c8fe2df3.svg") !important;
  background-repeat: no-repeat !important;
}

.gigya-screen .gigya-error-msg.gigya-error-msg-active {
  border-radius: .28rem;
  overflow: hidden;
  text-align: left !important;
  color: #000 !important;
  text-indent: .2rem !important;
  border: 1px solid #ff7079 !important;
  align-items: center !important;
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
  padding-left: .2rem !important;
  font-family: myriad-pro, sans-serif !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  display: flex !important;
}

.gigya-screen .gigya-error-msg.gigya-error-msg-active:before {
  content: url("circle-close.0693137d.svg");
  text-indent: 0;
  background-color: #ff7079;
  margin: -4px 5px -4px -4px;
  padding: 3px;
  justify-content: center !important;
  display: flex !important;
}

.gigya-valid {
  border: 1px solid #3c1192 !important;
}

input.gigya-input-text.gigya-error {
  border: 1px solid #ff7079 !important;
}

.gigya-screen .separator:before, .gigya-screen .separator:after {
  border-bottom: 1px solid #ff7d00 !important;
}

.gigya-screen .separator:not(:empty):after {
  margin-left: 20px !important;
}

.gigya-screen .separator:not(:empty):before {
  margin-right: 20px !important;
}

.gigya-show-checkmark.gigya-valid {
  background-image: none !important;
}

.gigya-screen input[type="submit"], .gigya-screen input[type="button"] {
  font-family: myriad-pro !important;
  font-size: 16px !important;
}

.gigya-screen a {
  font-family: myriad-pro !important;
}

.gigya-screen-dimmer, .gigya-screen .separator {
  display: none !important;
}

.content:after {
  content: "";
  display: block;
}

.mb-footer-container, .content:after {
  height: 5rem;
}

/*# sourceMappingURL=index.24f7ca55.css.map */
